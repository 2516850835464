@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-alice-carousel/lib/scss/alice-carousel.scss';

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'IBM Plex Sans Thai', sans-serif;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Customize daisy theme component */
@layer components {
  .btn-auto {
    @apply w-auto;
  }

  .btn-pagination {
    @apply text-black hover:text-white hover:bg-primary rounded;
  }

  .btn-pagination-arrow {
    @apply text-primary hover:text-white hover:bg-primary rounded;
  }

  .menu[class*=' p-'] li > * {
    @apply rounded-box py-2;
  }
}
